<template>
  <section class="policy-created">
    <div
      class="header-title-back flex hand"
      @click="() => $router.back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>
        Back
      </span>
    </div>

    <el-row
      :gutter="20"
      justify="space-around">
      <el-col

        :xs="24"
        :sm="24"
        :md="24"
        :lg="14"
        :xl="14">
        <div class="flex-sb-center">
          <div class="flex flex-aligin mg-b-20">
            <font-awesome-icon
              icon="tablet"
              class="title__icon"></font-awesome-icon>
            <h2 class="draft-title">{{ticketDetailTitle}}</h2>
          </div>
          <span
            v-if="detail.status==4"
            class="color-danger error-span hand"
            @click="handleDelTicket">Delete Ticket</span>
        </div>
        <div
          class="background-white ticket-card"
        >
          <div class="flex flex-justify">
            <div class="flex">
              <span class="margin-right gray-color title">Created Date {{detail.created_date}} {{detail.created_time}}</span>
              <span
                class="show-table-maxscreen span-options"
                :class="{
                  'dark':detail.status==2,
                  'green':detail.status==3,
                  'primary':detail.status==1,
                  'red':detail.status==4,
                }"
              >{{getOptionName('globalOptions.status',detail.status)}}</span>
            </div>
            <div>
              <!--                <v-button-->
              <!--                  v-if="item.is_want_renew"-->
              <!--                  class="button-blue"-->
              <!--                  @click="createRenew(item.order_no,detail.product_id)">Create Renew Quote</v-button>-->
              <v-button
                v-if="detail.status==2"
                class="button-theme show-table-maxscreen"
                @click="handlerEdit(detail.order_no,true,detail.product_id)"
              >Follow Up</v-button>
              <v-button
                v-if="detail.status==1"
                class="button-theme show-table-maxscreen"
                icon="link"
                @click="handlerEdit(detail.order_no,true,detail.product_id)"
              >Update Draft</v-button>
              <v-button
                v-if="detail.status==3"
                class="button-theme show-table-maxscreen"
                icon="link"
                @click="premiunInfo(detail.order_no)"
              >View Policy</v-button>
              <v-button
                v-if="detail.status==4"
                class="button-theme show-table-maxscreen"
                disabled
              >Edit</v-button>
              <span
                disabled
                class="show-table-minscreen span-options"
              >{{getOptionName('policyTypeOption',item.policy_type)}}</span>

            </div>
          </div>
          <div class="table">
            <div></div>
            <div
              class="tr">
              <span>Policy Holder</span>
              <span>{{detail.policyholder}}
              </span>
            </div>
            <div
              class="tr">
              <span>Tel No. </span>
              <span>{{detail.contact_no}}
              </span>
            </div>
            <div
              class="tr">
              <span>Product</span>
              <span>{{getOptionName('productTypeOptions',detail.product_id)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Last Updated</span>
              <span>{{detail.last_updated}}
              </span>
            </div>
          </div>
        </div>

      </el-col>
    </el-row>
    <v-checkbox
      v-model="status"
      :disabled="[1,3].includes(detail.status)"
      @change="handleMark">Mark as no show</v-checkbox>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import {getTicketDetail,hideTicket,delTicket} from '@/api/ticket'
import {mapActions} from 'vuex'
import {getOrderDetail} from '@api/order'
export default {
  name: 'ticketInfo',
  mixins: [MixinOptions],
  data(){
    return{
      detail:{},
      status:0
    }
  },
  computed:{
    detail_status(){
      let {status} = this.detail||{}
      return status||''
    },
    policy_id(){
      let {policy_id} = this.$route.query||{}
      return policy_id
    },
    showOtherInsurer(){
      let arr =  [1,4,11,3]
      return !arr.includes(this.detail.company_id)
    },
    ticketDetailTitle(){
      let {created_by,serial_number,created_date} = this.detail||{}
      let by = this.getOptionName('globalOptions.created_by',created_by)
      return created_date + by +' '+ serial_number
    },
    policyId(){
      return this.$route.query.policy_id
    },
    draftOrder(){
      let arr = this.detail.draft_orders||[]
      return arr[0]||{}
    },
    item(){
      return  this.draftOrder||{}
    },
    isDraft(){
      return this.$route.query.status===1
    },
    endorsementOptionType()
    {
      let {product_id} = this.detail||{}
      let typesArr = this.globalOptions.endorsement_type
      let arr = []
      typesArr.filter(item=>{
        if(item.product_id == product_id)
          arr = item.items
      })
      return arr.length<=0?[]:arr
    }
  },
  watch:{
    'detail.status':{
      immediate:true,
      handler(val){
        this.status = val == 4?1:0
      }
    }
  },
  created() {
    this.network().getTicketDetail()
  },
  methods:{
    ...mapActions('quotation', ['setQuoteInformationForm','setProductID']),
    handleMark(){
      let params = {
        status: this.status
      }
      if(this.status==1)
      {
        this.$ConfirmBox({
          title: '',
          message: 'Mark as no show?',
          confirmBtn:'Yes',
          cancelBtn:'No'
        }).then(() => {
          this.network().hideTicket(params)
        }).catch(()=>{
          this.status = 0
        })
      }
      else this.network().hideTicket(params)
    },
    endorsementType(data){
      let typeArr =[]
      data.forEach(item=>{
        this.endorsementOptionType.some(type => {
          if (type.id == item) {
            typeArr.push(type.name)
          }
        })
      })
      typeArr = typeArr.toString()
      return typeArr
    },
    statusData(id){
      let status = {
        10:'Pending Payment',
        30:'Inforce ',
        40:'Cancelled',
        50:'Expired'
      }
      return status[id]
    },
    handlerInfoHref(){
      this.$router.push('/quotation/create/info')
    },
    createRenew(order_no,productId){
      this.network().getOrderDetail(order_no,false,productId,true)
    },
    //draft edit 返回motor create quotation
    handlerEdit(order_no,bool,productId){
      this.network().getOrderDetail(order_no,true,productId)
    },
    handleDelTicket(){
      this.$ConfirmBox({
        title: '',
        message: ' Ticket will be remove and cannot be recovered, are you confirmed?',
        confirmBtn:'Confirm',
        cancelBtn:'Cancel'
      }).then(() => {
        this.network().delTicket()
      }).catch(()=>{
      })
    },
    //支付之后返回premiunInfo 页面详情
    premiunInfo(order_no){
      this.setQuoteInformationForm(null)
      this.$router.push({
        path:'/quotation/premium/info',
        query:{
          order_no:order_no
        }
      })
    },
    handlerPending({policy_type,order_no}){
      this.$router.push({
        name: 'info',
        query:{
          order_no,
          isEndorsement:policy_type==203
        }
      })
    },
    handleDraftPolicy(policy_id,type,status){
      this.$router.push({
        path:'/policy/info?policy_id=27&type=2&status=1',
        query:{
          policy_id,
          type,
          status
        }
      })
    },
    network() {
      return {
        getTicketDetail: async () => {
          let {ticket_id} = this.$route.query||{}
          const { data } = await getTicketDetail({
            ticket_id:ticket_id,
          })
          this.detail = data.data

        },
        delTicket: async () => {
          let {ticket_id} = this.$route.query||{}
          const { data } = await delTicket({
            ticket_id:ticket_id,
          })
          this.$router.push('/ticket/list')
        },
        getOrderDetail: async (order_no,isDraft,productId,isRenew) => {
          let { data } = await getOrderDetail({order_no:order_no})
          let params ={
            order_no,
            ...data.data,
            is_next:true,
          }
          if(isRenew)
          {
            params.order_status = ''
            params.policy_type = 201
          }
          this.setQuoteInformationForm(params)
          this.setProductID(productId)
          let path = {
            10:'/quotation/create/motor',
            11:'/quotation/create/contractors',
            12:'/quotation/create/travel',
            13:'/quotation/create/home',
            14:'/quotation/create/employee',
            15:'/quotation/create/business',
            16:'/quotation/create/domestic',
            17:'/quotation/create/construction',
            18:'/quotation/create/other',
          }

          this.$router.push({
            path:path[productId],
            query:{
              isDraft:isDraft,
              isRenew,
              order_no
            }
          })
        },
        hideTicket:async (params) => {
          let {ticket_id} = this.$route.query||{}
          const { data } = await hideTicket({
            ticket_id:ticket_id,
            ...params
          })
          this.network().getTicketDetail()
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.policy-created{
  .table{
    margin-top: 10px;
  }
  .background-white{
    padding:15px;
    border-radius: 8px;
    background: #fff;
    margin-bottom: 15px;

  }
  .text-end{
    text-align: end;
  }
  .flex{
    //justify-content: space-between;
    align-items: center;
    .company-logo{
      width: 80px;
      height: 42px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        display: block;
        width: 100%;
        //height: 42px;

      }
    }

  }
  .draft-title{
    //margin-bottom: 15px;
    margin-left: 15px;
  }
  .margin-right{
    //display: block;
    margin-right: 20px;
  }
  .title-content{
    font-size: 16px;
    font-weight: normal;
  }
  .title-margin-bottom{
    margin-bottom: 40px;
    .icon-little{
      margin: 0 14px;
      font-size: 12px;
    }
  }
  .box-content{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  }
  .background-gray {
    background: #EDF2F7 !important;
    ::v-deep .table {
      .tr {
        background: #EDF2F7 !important;
      }
    }
  }
  .retweet{
    margin-left: 5px;
  }
  .flex-wrap{
    flex-wrap: wrap;
    .z-index{
      z-index: 100;
    }
  }
  .title__icon{
    font-weight: 900;
    font-size: 24px;
    line-height: 18px;
    color: #718096
  }
  .ticket-card{
    .title{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      font-family: 'Inter';
    }
    .span-options{
      padding: 2px 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      background: #fff;
      border-radius: 4px;
      &.dark{
        color: #718096;
        border: 1px solid #718096;
      }
      &.primary{
        color: $primary;
        border: 1px solid $primary;
      }
      &.green{
        color: #10B981;
        border: 1px solid #10B981;
      }
      &.red{
        color: #FF4343;
        border: 1px solid #FF4343;
      }
    }
  }
  .error-span{
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
